/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

// BASICS
export const baseColors = {
  accent: '#C39600',
  black: '#000',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#B0AEB0',
    '#979797',
    '#101213',
  ],
  primary: '#004137',
  red: '#E6505A',
  secondary: '#036254',
  tertiary: '#F5F0E1',
  white: '#fff',
};

export const colors = {
  ...baseColors,
  background: baseColors.tertiary,
  danger: baseColors.red,
  dark: baseColors.gray[5],
  info: baseColors.primary,
  muted: baseColors.gray[1],
  primaryWhite: baseColors.white,
  secondary: baseColors.secondary,
  success: baseColors.primary,
  text: baseColors.gray[5],
  textMuted: baseColors.gray[3],
  warning: baseColors.accent,
};

export const fonts = {
  body: '"Source Sans 3", sans-serif',
  heading: '"Source Sans 3", sans-serif',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 400,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = {
  0: '0.75rem',
  1: '0.875rem',
  2: '1rem',
  3: '1.313rem',
  4: '1.5rem',
  5: '1.75rem',
  6: '1.875rem',
  7: '2.5rem',
  8: '2.813rem',
  9: '4.5rem',
  10: '5.5rem',
  11: '6rem',
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  lg: '1150px',
  md: '720px',
  sm: '540px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  pill: '50rem',
  lg: '0.3rem',
  sm: '0.2rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    color: colors.primary,
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

const heading = {
  color: text.heading.color,
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const styles = {
  a: {
    '&:hover': {
      color: colors.accent,
    },
    color: colors.secondary,
    textDecoration: 'underline',
  },
  blockquote: {
    backgroundColor: colors.background,
    color: colors.primary,
    fontStyle: 'normal',
    fontWeight: '500',
    textTransform: 'normal',
  },
  h1: {
    ...heading,
    fontSize: { desktop: '3.125rem', mobile: fontSizes[8], tablet: '2.25rem' },
    fontWeight: 700,
    lineHeight: { desktop: '3.5rem', mobile: '3.125rem', tablet: '2.5rem' },
  },
  h2: {
    ...heading,
    fontSize: { desktop: fontSizes[7], mobile: '2.1875rem', tablet: '2rem' },
    fontWeight: 700,
    lineHeight: { desktop: '2.6875rem', mobile: '2.375rem', tablet: '2.25rem' },
  },
  h3: {
    ...heading,
    fontSize: { desktop: fontSizes[6], mobile: '1.625rem', tablet: '1.25rem' },
    fontWeight: 700,
    lineHeight: { desktop: '2.1875rem', mobile: '1.875rem', tablet: '1.625rem' },
  },
  h4: {
    ...heading,
    fontSize: { desktop: '1.25rem', mobile: '1.125rem', tablet: '1.25rem' },
    fontWeight: 700,
    lineHeight: { desktop: '1.5rem', mobile: '1.375rem', tablet: '1.375rem' },
  },
  h5: {
    ...heading,
    fontSize: { desktop: fontSizes[2], mobile: fontSizes[2], tablet: fontSizes[2] },
    fontWeight: 700,
    lineHeight: { desktop: '1.25rem', mobile: '1.25rem', tablet: '1.25rem' },
  },
  highlight: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.dark,
  },
  li: {
    bulletBorderRadius: '10px',
    bulletColor: colors.primary,
  },
  p: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    margin: '0',
    marginBottom: '1.7rem',
    fontWeight: 400,
  },
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontSize: '1.125rem',
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  rteHighlight: {
    hoverColor: colors.secondary,
    hoverFooter: colors.secondary,
    color: colors.primary,
    fontWeight: '600',
    textDecoration: 'underline',
    isFooter: colors.white,
  },
  table: {
    borderCollapse: 'collapse',
    color: colors.gray[3],
    marginBottom: '3rem',
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '30px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: fontSizes[1],
  fontWeight: 400,
  height: 'auto',
  padding: '10px 26px 10px',
};

export const buttons = {
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
  primary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.secondary,
      borderColor: 'transparent',
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
  },
  'primary-with-background': {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.secondary,
    },
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primary,
  },
  secondary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: '#E3DCC6',
      borderColor: '#E3DCC6',
      color: colors.primary,
    },
    backgroundColor: colors.tertiary,
    borderColor: colors.tertiary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primary,
  },
  tertiary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      color: colors.white,
    },
    backgroundColor: colors.white,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primary,
  },
};

// COMPONENTS
export const adviceCardModule = {
  backgroundColor: colors.primary,
};

export const articleDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.primary,
    color: colors.tertiary,
  },
  title: colors.primary,
};

export const articleListing = {
  background: colors.primary,
  filter: colors.text,
  hover: colors.secondary,
  mobile: {
    background: colors.tertiary,
    title: colors.primary,
    text: colors.text,
  },
  title: colors.primary,
  text: colors.white,
};

export const breadcrumb = {
  backgroundColor: colors.tertiary,
  highlight: colors.primary,
};

export const cardCtas = {
  arrowColor: colors.primary,
  arrowHoverColor: colors.primary,
  color: colors.primary,
  fontSize: '0.75rem',
  hoverColor: colors.primary,
  letterSpacing: '2px',
  lineHeight: '13px',
  textTransform: 'uppercase',
};

export const cards = {
  article: {
    description: colors.text,
    link: {
      '&:hover': {
        color: colors.secondary,
        opacity: 1,
        textDecoration: 'underline',
      },
      color: colors.primary,
    },
    title: colors.primary,
  },
  logo: {
    color: colors.text,
  },
};

export const carousel = {
  controls: {
    hero: {
      backgroundColor: colors.white,
      color: colors.primary,
      stroke: colors.white,
      opacity: '0.8',
      hover: {
        backgroundColor: colors.white,
        color: colors.primary,
        stroke: colors.white,
      },
    },
    regular: {
      backgroundColor: colors.primary,
      color: colors.white,
      hover: {
        color: colors.white,
        backgroundColor: colors.secondary,
      },
    },
  },
  indicator: colors.secondary,
  link: {
    '&:hover': {
      color: colors.secondary,
      opacity: 1,
      textDecoration: 'underline',
    },
    color: colors.primary,
  },
  text: colors.text,
};

export const categoryCards = {
  backgroundColor: colors.primary,
};

export const categorySection = {
  background: colors.primary,
  boxShadow: null,
  chevron: {
    fill: colors.tertiary,
    stroke: colors.primary,
  },
  color: colors.white,
  date: colors.tertiary,
  dateColor: colors.primary,
  headingColor: colors.primary,
};

export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.primary,
  infoBlock: {
    background: colors.tertiary,
    button: {
      ...buttonDefaults,
      '&:hover': {
        backgroundColor: colors.secondary,
        textDecoration: 'none',
      },
      backgroundColor: colors.primary,
      color: colors.tertiary,
    },
    description: colors.text,
    title: colors.primary,
  },
};

export const contactForm = {
  heading: {
    color: colors.primary,
  },
};

export const contacts = {
  description: colors.text,
  office: {
    mapTextColor: colors.primary,
    mapTextFontSize: '0.875rem',
    mapTextFontWeight: 'normal',
    mapTextLetterSpacing: '2.33px',
    mapTextLineHeight: '1.71',
    mapTextTransform: 'uppercase',
    openingHoursFontSize: '1rem',
  },
  title: colors.primary,
};

export const cookieBanner = {
  backgroundColor: colors.primary,
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.primary,
    color: colors.white,
    titleColor: colors.white,
  },
  focusText: {
    backgroundColor: colors.tertiary,
    color: colors.text,
    titleColor: colors.primary,
  },
};

export const ctaGroup = {
  button: {
    '&:hover': {
      paddingRight: '18px',
      backgroundColor: colors.secondary,
      color: colors.white,
      textDecoration: 'none',
    },
    alignItems: 'center',
    backgroundColor: colors.primary,
    color: colors.tertiary,
    display: 'flex',
    fontFamily: fonts.heading,
    fontWeight: fontWeights.bold,
    fontSize: '2.125rem',
    gap: '1rem',
    justifyContent: 'space-between',
    textDecoration: 'none',
  },
  chevron: {
    opacity: 1,
  },
  title: colors.primary,
};

export const customBlockCards = {
  backgroundColor: colors.primary,
  color: colors.primary,
};

export const dealer = {
  textColor: colors.primary,
};

export const documentDownload = {
  arrowColor: colors.primary,
  arrowHoverColor: colors.primary,
  color: colors.primary,
  hoverColor: colors.primary,
};

export const footer = {
  activeColor: colors.accent,
  backgroundColor: colors.tertiary,
  color: colors.white,
  minHeight: '160px',
};

export const formInputs = {
  activeColor: colors.primary,
  borderRadius: '30px',
  inputPadding: '0.625rem 1.625rem',
  label: {
    fontSizes: ['12px', null, '16px'],
    lineHeights: ['21px', null, '26px'],
  },
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
  textareaPadding: '1rem 1.625rem',
};

export const header = {
  activeColor: colors.secondary,
  backgroundColor: colors.white,
  color: colors.dark,
};

export const headerBottom = {
  activeColor: colors.white,
  activeFontWeight: 'bold',
  backgroundColor: colors.accent,
  color: colors.white,
  fontSize: '12px',
  fontWeight: 'normal',
};

export const heroBannerButton = {
  'No Background': {
    '&:hover': {
      backgroundColor: '#E3DCC6',
      borderColor: '#E3DCC6',
      color: colors.primary,
    },
    backgroundColor: colors.tertiary,
    borderColor: colors.tertiary,
    borderStyle: 'solid',
    borderRadius: '24px',
    borderWidth: '1px',
    color: colors.primary,
    justifyContent: 'center',
    minHeight: '42px',
    padding: '13px 35px 12px',
  },
  'White Background': {
    '&:hover': {
      backgroundColor: '#E3DCC6',
      borderColor: '#E3DCC6',
      color: colors.primary,
    },
    backgroundColor: colors.tertiary,
    borderColor: colors.tertiary,
    borderStyle: 'solid',
    borderRadius: '24px',
    borderWidth: '1px',
    color: colors.primary,
    justifyContent: 'center',
    minHeight: '42px',
    padding: '13px 35px 12px',
  },
  arrow: {
    display: 'none',
  },
};

export const heroSection = {
  backgroundColor: colors.primary,
  descriptionColor: colors.white,
  marginTop: ['20px', '20px', null, '48px'],
  padding: ['20px', '30px', null, '60px'],
  textAlign: 'center',
  titleColor: colors.white,
  titleFontWeight: '700',
};

export const imageAndText = {
  color: colors.secondary,
};

export const infoBlock = {
  background: colors.tertiary,
  button: {
    '&:hover': {
      backgroundColor: colors.secondary,
      textDecoration: 'none',
    },
    backgroundColor: colors.primary,
    color: colors.tertiary,
  },
  description: colors.text,
  title: colors.primary,
};

export const infoSection = {
  backgroundColor: colors.primary,
  color: colors.white,
  title: colors.white,
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.secondary,
};

export const media = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};

export const mobileNavigation = {
  arrowColor: colors.primary,
  chevronColor: colors.primary,
};

export const navItems = {
  fontSize: '0.75rem',
  letterSpacing: '2px',
};

export const newsListing = {
  titleFontWeight: 'normal',
};

export const ownerDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.primary,
    color: colors.tertiary,
  },
  title: colors.primary,
};

export const product = {
  backgroundColor: colors.secondary,
  color: colors.white,
};

export const productListing = {
  titleFontWeight: 'normal',
};

export const salesPerson = {
  borderColor: colors.primary,
};

export const search = {
  activeColor: colors.primary,
  borderRadius: '20px',
};

export const shortBanner = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleColor: colors.white,
  titleFontWeight: 'bold',
  titleTextTransform: 'none',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.secondary,
};

export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  breadcrumb,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'Eurotuin',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};

import { useConfiguration } from '@arvesta-websites/configuration';
import React, { Fragment, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass';

import type { EventType, FieldType, FormDataType, FormSubmitResponseType } from '../../../../types';
import { Config, EMAIL_REGEX } from '../../../../utils';
import Button from '../../Button';
import { withErrorBoundary } from '../../ErrorBoundary';
import InputField from '../InputField';
import RadioFieldGroup from '../RadioFieldGroup';

import OptionalDropdown from './OptionalDropdown';
import {
  StyledContainer,
  StyledDescription,
  StyledError,
  StyledForm,
  StyledFormRow,
  StyledHeader,
  StyledHeading,
  StyledSuccess,
  StyledTitleWrapper,
} from './Styled';

export interface ContactsFormDefaultProps extends BoxProps {
  /** title */
  title: string;
  /** optional short description */
  shortDescription?: string;
  /** submit handler */
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => FormSubmitResponseType;
  /** show email subscribe options */
  showSubscribeOptions?: boolean;
  basicPageTitle?: string;
  NETLIFY_FORM_NAME: string;
  ONE_TRUST_ID?: string;
  optionalFields?: any[];
}

const defaultFormDataType: FormDataType = {
  email: { error: '', name: 'email', value: '' },
  message: { error: '', name: 'message', value: '' },
  name: { error: '', name: 'name', value: '' },
  newsletter: { error: '', name: 'newsletter', value: '' },
};

const fieldName = (type: string, idx: number, suffix?: string) =>
  suffix ? `${type.toLowerCase()}-${idx + 1}-${suffix}` : `${type.toLowerCase()}-${idx + 1}`;

const generateFormData = (optionalFields?: any[]) => {
  if (!optionalFields?.length) return { ...defaultFormDataType };
  const extraFormData = optionalFields.reduce((obj, field, idx) => {
    const name = fieldName(field.type, idx);
    obj[name] = { error: '', name, value: '' };
    if (field.type === 'Dropdown' && field.addOtherOptionToDrowdown) {
      const otherName = fieldName(field.type, idx, 'other');
      obj[otherName] = { error: '', name: otherName, value: '' };
    }
    return obj;
  }, {});
  return { ...defaultFormDataType, ...extraFormData };
};

const PHONE_REGEX = /^(\d|\+){1}[\d ]+$/i;

const validateField = (
  name: keyof FormDataType,
  formData: FormDataType,
  required: boolean,
  dropdownOtherValue: string,
) => {
  const error = '';
  const { value } = formData[name];
  let verifyKey = name as string;
  let dropdownKey;
  if (/telephone/gim.test(name)) verifyKey = 'telephone';
  if (/dropdown/gim.test(name) && !/other/gim.test(name)) verifyKey = 'dropdown';
  if (/dropdown/gim.test(name) && /other/gim.test(name)) {
    verifyKey = 'dropdownOther';
    dropdownKey = name.replace('-other', '') as keyof FormDataType;
  }

  switch (verifyKey) {
    case 'name':
      if (!value.length) {
        return 'forms.contact.errors.required';
      }
      break;
    case 'message':
      if (!value.length) {
        return 'forms.contact.errors.required';
      }
      break;
    case 'newsletter':
      if (!value.length && required) {
        return 'forms.contact.errors.required';
      }
      break;
    case 'email':
      if (!value.length) {
        return 'forms.contact.errors.required';
      } else if (!EMAIL_REGEX.test(value)) {
        return 'forms.contact.errors.email';
      }
      break;
    case 'telephone':
      if (!value.length && required) return 'forms.contact.errors.required';
      if (value.length && !PHONE_REGEX.test(value)) return 'forms.contact.errors.telephone';
      break;
    case 'dropdown':
      if (!value.length && required) {
        return 'forms.contact.errors.required';
      }
      break;
    case 'dropdownOther':
      if (!value.length && required && dropdownKey && formData[dropdownKey].value === dropdownOtherValue) {
        return 'forms.contact.errors.required';
      }
      break;
    default:
      return;
  }

  return error;
};

export const ContactsFormDefault = ({
  title,
  shortDescription,
  handleFormSubmit,
  showSubscribeOptions,
  basicPageTitle,
  NETLIFY_FORM_NAME,
  optionalFields,
  ...rest
}: ContactsFormDefaultProps) => {
  const [cookies] = useCookies(['OptanonConsent', 'OptanonAlertBoxClosed']);
  const intl = useIntl();
  const recaptchaRef = React.useRef();
  const initialFormData = useMemo(() => generateFormData(optionalFields), [optionalFields]);
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitStatus, setSubmitStatus] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');
  const [captchaSolved, setCaptchaSolved] = React.useState(Config.RECAPTCHA_KEY ? false : true);
  const netlifyForm = `${NETLIFY_FORM_NAME}-${intl.locale}`;
  const { ContactFormHeading } = useConfiguration();
  const translatedOtherString = intl.formatMessage({ id: 'forms.contact.other' });

  if (!NETLIFY_FORM_NAME) throw new Error('NETLIFY_FORM_NAME name missing, form will be unprocessable');

  const updateFormField = (value: string, field: keyof FormDataType, required: boolean) => {
    const update = { ...formData };
    update[field].value = value;
    const errorId = validateField(field, formData, required, translatedOtherString);
    update[field].error = errorId ? intl.formatMessage({ id: errorId }) : '';
    setFormData(update);
  };

  const handleInputChange = (e: EventType, field: keyof FormDataType, required: boolean) => {
    const { value } = e.target as HTMLInputElement;
    updateFormField(value, field, required);
  };

  const handleRadioChange = (
    _e: React.FormEvent<HTMLInputElement>,
    field: string,
    value: string,
    required: boolean,
  ) => {
    updateFormField(value, field as keyof FormDataType, required);
  };

  const handleSelectionChange = (value: string, field: keyof FormDataType, required: boolean) => {
    setSelectedOption(value);
    updateFormField(value, field, required);
    const otherKey = `${field}-other` as keyof FormDataType;
    const otherField = formData[otherKey];
    if (value !== translatedOtherString && (otherField.value || otherField.error)) {
      updateFormField('', otherKey, required);
    }
  };

  const isFieldRequired = (name: string) => {
    if (name === 'newsletter') return !!showSubscribeOptions;
    if (name.startsWith('telephone') || name.startsWith('dropdown')) {
      const [_, idxUp] = name.split('-');
      const idx = parseInt(idxUp) - 1;
      return !!optionalFields && !!optionalFields[idx]?.isRequiredField;
    }
    return true;
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const newFormData: FormDataType = { ...formData };
    const values: { [key: string]: string } = {};
    let errors = false;

    Object.keys(formData).forEach((key: string) => {
      values[key] = formData[key as keyof FormDataType].value;
      const errorId = validateField(key as keyof FormDataType, formData, isFieldRequired(key), translatedOtherString);
      newFormData[key as keyof FormDataType].error = errorId ? intl.formatMessage({ id: errorId }) : '';
      if (newFormData[key as keyof FormDataType].error.length) {
        errors = true;
      }
    });

    if (errors || !captchaSolved) {
      setFormData(newFormData);
    } else {
      setSubmitting(true);

      (async function performSubmission() {
        const result: any = await handleFormSubmit(netlifyForm, { ...values, pageTitle: basicPageTitle ?? 'Contact' });
        if (!result.status || result.status !== 200) {
          setSubmitStatus('error');
          setSubmitting(false);
        } else {
          setSubmitStatus('success');
          const reset: FormDataType = JSON.parse(JSON.stringify(formData));
          Object.values(reset).forEach((entry: FieldType) => {
            entry.value = '';
          });
          setFormData(reset);
        }
      })();
    }
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setCaptchaSolved(true);
    } else {
      setCaptchaSolved(false);
    }
  };

  const hasOneTrustEnabled = process.env.ONE_TRUST_ID;
  const hasConsent = hasOneTrustEnabled && cookies.OptanonAlertBoxClosed;

  return (
    <StyledContainer {...rest}>
      <StyledHeader>
        <StyledTitleWrapper>
          <StyledHeading>{title}</StyledHeading>
          <ContactFormHeading />
        </StyledTitleWrapper>
        {shortDescription && submitStatus !== 'success' && <StyledDescription>{shortDescription}</StyledDescription>}
      </StyledHeader>
      <StyledForm
        as="form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        name={netlifyForm}
        onSubmit={handleSubmit}
      >
        <input name="form-name" type="hidden" value={netlifyForm} />

        <label hidden htmlFor="pageTitle">
          {intl.formatMessage({ id: 'forms.contact.pageTitle' })}
        </label>
        <input id="pageTitle" name="pageTitle" type="hidden" value={basicPageTitle ?? 'contact'} />

        {submitStatus !== 'success' && (
          <>
            <InputField
              field={formData.name}
              handleChange={(...args) => handleInputChange(...args, true)}
              label={intl.formatMessage({ id: 'forms.contact.fullname.label' })}
              placeholder={intl.formatMessage({ id: 'forms.contact.fullname.placeholder' })}
            />

            <InputField
              field={formData.email}
              handleChange={(...args) => handleInputChange(...args, true)}
              label={intl.formatMessage({ id: 'forms.contact.email.label' })}
              placeholder={intl.formatMessage({ id: 'forms.contact.email.placeholder' })}
            />

            {optionalFields &&
              optionalFields.length > 0 &&
              optionalFields.map((field, idx) => {
                const name = fieldName(field.type, idx) as keyof FormDataType;
                if (field.type === 'Telephone') {
                  return (
                    <InputField
                      key={field.id}
                      type="tel"
                      field={formData[name]}
                      handleChange={(...args) => handleInputChange(...args, field.isRequiredField)}
                      label={field.fieldLabel}
                      placeholder={field.helpText || intl.formatMessage({ id: 'forms.contact.telephone.placeholder' })}
                    />
                  );
                }
                if (field.type === 'Dropdown') {
                  const nameOther = fieldName(field.type, idx, 'other') as keyof FormDataType;
                  return (
                    <OptionalDropdown
                      key={field.id}
                      contentfulFieldData={field}
                      selectedOption={selectedOption}
                      dropdownField={formData[name]}
                      otherField={formData[nameOther]}
                      handleDropdownChange={value => handleSelectionChange(value, name, field.isRequiredField)}
                      handleOtherChange={(...args) => handleInputChange(...args, field.isRequiredField)}
                    />
                  );
                }
              })}

            {/**
             * netlify forms work by build-time detection, so any fields that aren't available then don't get registered or parsed.
             * So we need to add hidden fields for any optionals...
             **/}
            {optionalFields &&
              optionalFields.length > 0 &&
              optionalFields.map((field, idx) => {
                if (field.type === 'Telephone') return;
                const name = fieldName(field.type, idx) as keyof FormDataType;
                const otherName = fieldName(field.type, idx, 'other') as keyof FormDataType;
                return (
                  <Fragment key={field.id}>
                    <label htmlFor={name} hidden>
                      {field.fieldLabel}
                      <input id={name} type="hidden" name={name} value={formData[name]?.value ?? ''} />
                    </label>
                    {field.addOtherOptionToDrowdown && (
                      <label htmlFor={otherName} hidden>
                        {translatedOtherString}
                        <input id={otherName} type="hidden" name={otherName} value={formData[otherName]?.value ?? ''} />
                      </label>
                    )}
                  </Fragment>
                );
              })}

            <InputField
              field={formData.message}
              handleChange={(...args) => handleInputChange(...args, true)}
              label={intl.formatMessage({ id: 'forms.contact.message.label' })}
              placeholder={intl.formatMessage({ id: 'forms.contact.message.placeholder' })}
              textarea
            />

            {showSubscribeOptions && (
              <RadioFieldGroup
                field={formData.newsletter}
                handleChange={(...args) => handleRadioChange(...args, true)}
                label={intl.formatMessage({ id: 'forms.contact.newsletter.title' })}
                options={[
                  { label: intl.formatMessage({ id: 'globals.yes' }), name: 'yes' },
                  { label: intl.formatMessage({ id: 'globals.no' }), name: 'no' },
                ]}
              />
            )}
            {Config.IS_DEVELOPMENT_URL && Config.RECAPTCHA_KEY ? (
              <StyledFormRow>
                <ReCAPTCHA
                  hl={intl.locale}
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                  sitekey={Config.RECAPTCHA_KEY}
                  size="compact"
                />
              </StyledFormRow>
            ) : (
              ((hasOneTrustEnabled && hasConsent) || !hasOneTrustEnabled) &&
              Config.RECAPTCHA_KEY && (
                <StyledFormRow>
                  <ReCAPTCHA
                    hl={intl.locale}
                    onChange={onCaptchaChange}
                    ref={recaptchaRef}
                    sitekey={Config.RECAPTCHA_KEY}
                    size="compact"
                  />
                </StyledFormRow>
              )
            )}

            <StyledFormRow>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  Config.IS_DEVELOPMENT_URL
                    ? submitting || !captchaSolved
                    : (hasOneTrustEnabled && !hasConsent) || submitting || !captchaSolved
                }
              >
                {intl.formatMessage({ id: 'forms.contact.submit' })}
              </Button>
            </StyledFormRow>
          </>
        )}
        {submitStatus && (
          <>
            {submitStatus === 'success' && (
              <StyledSuccess>{intl.formatMessage({ id: 'forms.contact.success' })}</StyledSuccess>
            )}
            {submitStatus === 'error' && (
              <StyledError>{intl.formatMessage({ id: 'forms.contact.failure' })}</StyledError>
            )}
          </>
        )}
      </StyledForm>
    </StyledContainer>
  );
};

export default withErrorBoundary(ContactsFormDefault, { componentName: 'ContactsFormDefault' });

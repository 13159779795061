import { useConfiguration } from '@arvesta-websites/configuration';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useMemo } from 'react';
import { tv } from 'tailwind-variants';

import { Heading, HeadingTag, withErrorBoundary } from '../../components';
import { Cta } from '../../components/Cta';

import BannerOverlay from './BannerOverlay';
import VideoBanner from './VideoBanner';

interface CTA {
  to: string;
  label: string;
}

interface Image {
  title?: string;
  altText?: string;
  asset: {
    gatsbyImageData: IGatsbyImageData;
    file: { url: string; fileName: string };
  };
}

type CtaStyle = 'White Background' | 'No Background';
type AlignText = 'Left' | 'Center' | 'Right';
type Height = 'Regular' | 'Large';
export interface HeroBannerProps {
  key: string;
  image: Image;
  video?: { url: string };
  alignText: AlignText;
  description?: string;
  cta?: CTA;
  secondCta?: CTA;
  title?: string;
  titleTag: HeadingTag;
  ctaStyle?: CtaStyle;
  secondCtaStyle?: CtaStyle;
  partOfCarousel?: boolean;
  height?: Height;
  overlayDesktop?: string;
  overlayMobile?: string;
  overlayTablet?: string;
}

const heroBanner = tv({
  slots: {
    actionsWrapper:
      'flex flex-col lg:flex-row text-center gap-[15px] lg:gap-2.5 items-center mt-[20px] justify-center lg:justify-normal',
    callToAction:
      'appearance-none no-underline flex items-center justify-center text-[14px] herobannerButton mr-[7px] min-w-[159px] pt-[13px] pb-[12px] px-[35px] cursor-pointer min-h-[42px] transform ease-in-out rounded-button',
    componentWrapper:
      'block md:flex absolute flex-col justify-center font-bold text-heroBanner-text items-center top-[96px] sm:top-[67px] md:top-[88px] lg:top-0 left-0 w-full bottom-[15%] lg:bottom-0 text-[30px] lg:text-10 xl:text-[45px] lg:h-full text-center lg:text-left py-0 px-2.5 lg:p-0 z-20',
    gatsbyImage:
      'h-full my-0 mx-auto object-cover after:absolute after:h-full after:w-full after:top-0 after:left-0 after:content-[""]',
    heroDescription: 'heroDescription leading-[21px] md:leading-[22px] lg:leading-[27px] pt-[23px]',
    heroTitle: 'drop-shadow heroTitle w-full leading-9 md:leading-[42px] lg:leading-[54px]',
    imageWrapper: 'relative inline-block w-full',
  },
  variants: {
    ctaVariant: {
      Primary: {
        callToAction:
          'bg-heroBanner-button-primary hover:bg-heroBanner-button-primary-hover text-heroBanner-button-primary-text hover:text-heroBanner-button-primary-text-hover border-none h-full',
      },
      Secondary: {
        callToAction:
          'bg-heroBanner-transparent hover:bg-heroBanner-button-secondary-hover text-heroBanner-button-secondary-text hover:text-heroBanner-button-secondary-text-hover border-2 border-heroBanner-button-secondary hover:border-heroBanner-button-secondary-hover',
      },
    },
    height: {
      Large: {
        imageWrapper: 'h-[420px]',
      },
      Regular: {
        imageWrapper: 'h-[327px]',
      },
    },
    position: {
      Center: {
        componentWrapper: 'items-center',
        heroDescription: 'text-center lg:w-[660px]',
        heroTitle: 'itens-center text-center lg:w-[800px]',
      },
      Left: {
        componentWrapper: 'items-center lg:items-start lg:left-[15.5%]',
        heroDescription: 'lg:w-[660px]',
        heroTitle: 'items-center lg:text-left lg:w-[550px]',
      },
      Right: {
        componentWrapper: 'items-center lg:items-end lg:right-[15.5%] lg:left-auto',
        heroDescription: 'lg:text-right lg:w-[660px] ',
        heroTitle: 'items-center lg:text-right lg:w-[550px]',
      },
    },
  },
});

const HeroBanner = ({
  alignText,
  cta,
  ctaStyle,
  description,
  image,
  secondCta,
  secondCtaStyle,
  title,
  titleTag,
  video,
  partOfCarousel,
  height,
  overlayDesktop,
  overlayMobile,
  overlayTablet,
}: HeroBannerProps) => {
  const config = useConfiguration();

  const { actionsWrapper, callToAction, componentWrapper, gatsbyImage, heroDescription, heroTitle, imageWrapper } =
    heroBanner();

  const isVariant = useMemo(
    () => (ctaStyle?: CtaStyle) => (ctaStyle === 'White Background' ? 'Primary' : 'Secondary'),
    [],
  );
  const isCustom = useMemo(() => {
    if (config.heroBanner.customOverlay) {
      return height ?? 'Regular';
    }
    return 'Regular';
  }, [config.heroBanner.customOverlay, height]);

  if (video?.url) {
    return <VideoBanner videoUrl={video.url} imageUrl={image.asset.file.url} />;
  }

  if (!image) return null;

  return (
    <div className={imageWrapper({ height: isCustom })}>
      <GatsbyImage
        alt={image?.altText || image.title || ''}
        image={image.asset?.gatsbyImageData}
        className={gatsbyImage()}
      />
      <div className={componentWrapper({ position: alignText })}>
        {title && (
          <Heading tag={titleTag} variant="h2" className={heroTitle({ position: alignText })}>
            {title}
          </Heading>
        )}
        {description && <div className={heroDescription({ position: alignText })}>{description}</div>}
        {cta?.to && (
          <div className={actionsWrapper()}>
            <Cta to={cta.to} className={callToAction({ ctaVariant: isVariant(ctaStyle) })}>
              {cta.label}
            </Cta>
            {secondCta?.to && (
              <Cta to={secondCta.to} className={callToAction({ ctaVariant: isVariant(secondCtaStyle) })}>
                {secondCta.label}
              </Cta>
            )}
          </div>
        )}
      </div>

      <BannerOverlay
        partOfCarousel={partOfCarousel}
        overlayDesktop={overlayDesktop}
        overlayMobile={overlayMobile}
        overlayTablet={overlayTablet}
      />
    </div>
  );
};

export default withErrorBoundary(HeroBanner, { componentName: 'HeroBanner' });
